import type { UserRoleType } from '~/types/User';

export type RoleList = {
  id: UserRoleType;
  name: string;
  details: string[];
};
const roleList: RoleList[] = [
  {
    id: 'EMPLOYEE',
    name: 'พนักงาน',
    details: ['ไม่สามารถใช้งานอะไรได้เลย'],
  },
  {
    id: 'ACCOUNT',
    name: 'บัญชี',
    details: ['เข้าถึงหน้าที่เกี่ยวข้องกับการบัญชีทั้งหมด'],
  },
  {
    id: 'ADMIN_QUOTATION',
    name: 'แอดมินใบสเปค',
    details: ['เข้าถึงการสร้าง แก้ไข หรือลบใบสเปค', 'เข้าถึงหน้าตารางจัดส่งงาน'],
  },
  {
    id: 'GRAPHIC',
    name: 'กราฟฟิก',
    details: ['เข้าถึงฟีเจอร์ที่เกี่ยวกับกราฟฟิกทั้งหมด'],
  },
  {
    id: 'MERCHANDISER',
    name: 'merchandiser',
    details: ['สามารถเข้าถึงฟีเจอร์ที่เกี่ยวกับ Merchandiser', 'จะมีชื่อของผู้ใช้ดังกล่าวในตัวเลือกชื่อ Merchandiser'],
  },
  {
    id: 'PRODUCTION',
    name: 'ฝ่ายผลิต',
    details: ['เข้าถึงหน้าตรวจนับสินค้า', 'เข้าถึงหน้า Master plan', 'เข้าถึงหน้าที่เกี่ยวข้องกับการผลิตทั้งหมด'],
  },
  {
    id: 'SALE',
    name: 'ฝ่ายขาย',
    details: ['เข้าถึงหน้าใบสรุปฝ่ายขาย', 'เข้าถึงหน้าใบมัดจำ', 'เข้าถึงหน้า Email contact', 'เข้าถึงหน้าที่เกี่ยวข้องกับต้นทุนทั้งหมด', 'จะมีชื่อของผู้ใช้ดังกล่าวในตัวเลือกชื่อ Sale'],
  },
  {
    id: 'STORE',
    name: 'ฝ่ายสโตร์',
    details: ['เข้าถึงหน้าจัดซื้อ-สโตร์ทั้งหมด'],
  },
  {
    id: 'SUPERADMIN',
    name: 'ผู้ดูแลระบบ',
    details: ['สามารถเข้าถึงทุกส่วนของระบบได้'],
  },
];
export default roleList;
