<script lang="ts" setup>
// PROPS
interface Props {
    role: string;
    usersAmount: number;
    isActive: boolean;
}
const props = defineProps<Props>();
const { role, usersAmount, isActive } = toRefs(props);
const activeClass = 'bg-stone-300 hover:bg-stone-300/80 text-white dark:bg-stone-600 hover:dark:bg-stone-600/80';
const nonActiveClass = 'dark:hover:bg-stone-600/80';
</script>
<template>
    <button type="button" :class="[isActive ? activeClass : nonActiveClass]" class="dark:text-white capitalize flex justify-between w-full font-medium rounded-lg text-sm px-4 py-2.5 transition">
        {{ role }}
        <span class="bg-primary-100 text-primary-800 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-primary-drak-400 dark:text-primary-500">{{ usersAmount }}</span>
    </button>
</template>

<style></style>
