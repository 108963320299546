export default {
    project: {
        create: false,
        update: false,
        delete: false,
        view: false,
    },
    specSheet: {
        create: false,
        update: false,
        delete: false,
        view: false,
        approve: false,
    },
    quotation: {
        create: false,
        update: false,
        delete: false,
        view: false,
        approve: false,
    },
    invoice: {
        create: false,
        update: false,
        delete: false,
        view: false,
        approve: false,
    },
    pr: {
        create: false,
        update: false,
        delete: false,
        view: false,
        approve: false,
    },
    gr: {
        create: false,
        update: false,
        delete: false,
        view: false,
        approve: false,
    },
    po: {
        create: false,
        update: false,
        delete: false,
        view: false,
        approve: false,
        issueOnPEAK: false,
    },
    supplier: {
        create: false,
        update: false,
        delete: false,
        view: false,
    },
    workOrder: {
        create: false,
        update: false,
        delete: false,
        view: false,
        approve: false,
        upStatus: false,
    },
    // reserveMaterial: {
    //     create: false,
    //     update: false,
    //     delete: false,
    //     view: false,
    //     approve: false,
    // },
    planner: {
        create: false,
        update: false,
        delete: false,
        view: false,
    },
    control: {
        create: false,
        update: false,
        delete: false,
        view: false,
        canProduce: false,
    },
    customer: {
        view: false,
        create: false,
        update: false,
        delete: false,
    },
    controlMonitor: {
        create: false,
        update: false,
        delete: false,
        view: false,
    },
    expense: {
        create: false,
        update: false,
        delete: false,
        view: false,
    },
    withholding: {
        view: false,
    },
    payment: {
        create: false,
        update: false,
        delete: false,
        view: false,
    },
    product: {
        create: false,
        update: false,
        delete: false,
        view: false,
    },
    adjustment: {
        create: false,
        update: false,
        delete: false,
        view: false,
        approve: false,
        issueOnPEAK: false,
    },
    materialRequest: {
        create: false,
        update: false,
        delete: false,
        view: false,
        approve: true,
    },
    materialDelivery: {
        create: false,
        update: false,
        delete: false,
        view: false,
        approve: false,
        prepare: false,
        receive: false,
    },
    materialSurplusReturn: {
        create: false,
        update: false,
        delete: false,
        view: false,
        approve: false,
        receive: false,
    },
    materialSummary: {
        create: false,
        update: false,
        delete: false,
        view: false,
        approve: false,
    },
    warehouse: {
        create: false,
        update: false,
        delete: false,
        view: false,
    },
    user: {
        create: false,
        update: false,
        delete: false,
        view: false,
    },
    dropdown: {
        create: false,
        update: false,
        delete: false,
        view: false,
    },
    productionEmployee: {
        create: false,
        update: false,
        delete: false,
        view: false,
    },
    systemSetting: {
        update: false,
    },
};
