<script setup lang="ts">
import cloneDeep from 'lodash/cloneDeep';
import UserList from '@/components/settings/system/users/Component/UserList.vue';
import SearchBar from '@/components/global/SearchBar.vue';
import roleList from '@/helpers/roleList';
import RoleListTab from '@/components/settings/system/users/Component/RoleListTab.vue';
import LoadingScreen from '~/components/global/LoadingScreen.vue';
import type { UserResponse, UserRoleType } from '@/types/User';
import userItem from '@/Functions/Setting/User/template';
import DialogLoading from '~/components/ui/dialog/DialogLoading.vue';

// LAZY LOAD
const UserCreateModal = defineAsyncComponent(() => import('@/components/settings/system/users/Component/UserCreateModal.vue'));
const UserUpdateModal = defineAsyncComponent(() => import('@/components/settings/system/users/Component/UserUpdateModal.vue'));
const UserRoleDetailsModal = defineAsyncComponent(() => import('@/components/settings/system/users/Component/UserRoleDetailsModal.vue'));

definePageMeta({
    middleware: ['super-admin'],
});

useHead({
    title: 'การตั้งค่าผู้ใช้ระบบ',
});

// VARIABLE
const activeRole = ref<UserRoleType | 'all'>('all');
const { $toast } = useNuxtApp();
const users = ref<UserResponse[]>([]);
const selectedUser = ref<UserResponse>(cloneDeep(userItem));
const isLoading = ref<boolean>(true);
const searchText = ref<string>('');
const roleDetails = ref<string[]>([]);
const activeUserId = ref<number>(0);
const userCreateOpenState = ref<boolean>(false);
const userUpdateOpenState = ref<boolean>(false);
const userRoleDetailOpenState = ref<boolean>(false);

// FUNCTION
async function getUser(): Promise<UserResponse[]> {
    try {
        const { data, error } = await useApiFetch<UserResponse[]>('/v1/users/get', {
            method: 'POST',
        });

        // HANDLE ERROR
        if (error.value) throw error.value;
        if (!data.value) throw new Error(JSON.stringify(error.value));

        return data.value || [];
    } catch (err) {
        $toast.error('เกิดปัญหาระหว่างการดึงข้อมูลผู้ใช้งาน');
        useBugsnag().notify(JSON.stringify(err));
        return [];
    }
}

async function refreshUsers() {
    isLoading.value = true;
    users.value = await getUser();
    selectedUser.value = cloneDeep(userItem);
    isLoading.value = false;
}

function openRoleDetailsModal() {
    if (activeRole.value !== 'all') {
        const roleData = roleList.find((role) => role.id === activeRole.value);
        roleDetails.value = roleData?.details || [];
    }

    userRoleDetailOpenState.value = true;
}

function openUserCreateModal() {
    userCreateOpenState.value = true;
}

function openUserUpdateModal(editUserId: number) {
    activeUserId.value = editUserId;
    userUpdateOpenState.value = true;
}

// COMPUTED
const filteredUser = computed(() => {
    return users.value.filter((user: UserResponse) => {
        const isMatchNameSearch = user.fullName.toLowerCase().includes(searchText.value.toLowerCase()) || user.username.toLowerCase().includes(searchText.value.toLowerCase());
        if (activeRole.value !== 'all') {
            return isMatchNameSearch && user.actionRole.includes(activeRole.value);
        } else {
            return isMatchNameSearch;
        }
    });
});

// ON MOUNTED
onMounted(async () => {
    users.value = await getUser();
    isLoading.value = false;
});
</script>
<template>
    <div>
        <NuxtLayout page-name="ตั้งค่าผู้ใช้งาน">
            <div v-if="!isLoading">
                <div class="grid grid-cols-1 md:grid-cols-12 gap-3">
                    <!-- ROLE LIST -->
                    <div class="md:col-span-3">
                        <RoleListTab v-model="activeRole" :users="users" />
                    </div>

                    <!-- USER LIST -->
                    <div class="md:col-span-9 rounded-xl drop-shadow border bg-white dark:bg-stone-800 dark:border-stone-600">
                        <!-- TOOLS BAR -->
                        <div class="flex flex-col md:flex-row gap-3 md:gap-0 justify-between border-b dark:border-stone-600 p-3">
                            <div class="flex flex-col md:flex-row md:items-start md:space-x-2 gap-3 md:gap-0">
                                <Button type="button" @click="openUserCreateModal()">
                                    <IconCSS name="material-symbols:add-rounded" size="1.5rem" />
                                    เพิ่มผู้ใช้งาน
                                </Button>
                                <Button v-if="activeRole != 'all'" variant="secondary-900" type="button" @click="openRoleDetailsModal()">
                                    <IconCSS name="material-symbols:visibility-outline-rounded" size="1.5rem" /> สิทธิ์การเข้าถึง
                                </Button>
                            </div>
                            <div class="w-full md:w-1/2 flex justify-end">
                                <SearchBar v-model="searchText" class="w-full" :placeholder="'ค้นหาด้วยชื่อ-นามสกุล'" />
                            </div>
                        </div>

                        <!-- USER -->
                        <div class="p-3 space-y-3">
                            <UserList v-for="user in filteredUser" :key="'user-' + user.fullName" v-model="selectedUser" :user="user" @edit="openUserUpdateModal(user.id)" />
                        </div>
                    </div>
                </div>

                <!-- ADD USER MODAL -->
                <Suspense v-if="userCreateOpenState">
                    <UserCreateModal v-model:open="userCreateOpenState" user-id="activeUserId" @refresh-user="refreshUsers()" />
                    <template #fallback>
                        <DialogLoading></DialogLoading>
                    </template>
                </Suspense>

                <!-- EDIT USER MODAL -->
                <Suspense v-if="userUpdateOpenState">
                    <UserUpdateModal v-model:open="userUpdateOpenState" v-model="selectedUser" :user-id="activeUserId" @refresh-user="refreshUsers()" />
                    <template #fallback>
                        <DialogLoading></DialogLoading>
                    </template>
                </Suspense>

                <!-- ROLE DETAILS MODAL -->
                <Suspense v-if="userRoleDetailOpenState">
                    <UserRoleDetailsModal v-model:open="userRoleDetailOpenState" :role-details="roleDetails" />
                    <template #fallback>
                        <DialogLoading></DialogLoading>
                    </template>
                </Suspense>
            </div>
            <LoadingScreen v-else />
        </NuxtLayout>
    </div>
</template>
