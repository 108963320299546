<script setup lang="ts">
import type { UserResponse, UserRoleType } from '@/types/User';
import roleList from '@/helpers/roleList';
import RoleListButton from '@/components/settings/system/users/Component/RoleListButton.vue';

// PROPS
interface Props {
    users: UserResponse[];
}
const props = defineProps<Props>();
const { users } = toRefs(props);

// DEFINEMODEL
const activeRole = defineModel<UserRoleType | 'all'>({ required: true });

// FUNCTION
function isActiveRole(role: UserRoleType | 'all'): boolean {
    return role === activeRole.value;
}
function setActiveRole(role: UserRoleType | 'all') {
    activeRole.value = role;
}

function countUserEachRole(role: UserRoleType) {
    return users.value.reduce((prev: number, user: UserResponse) => {
        if (user.actionRole.includes(role)) {
            return prev + 1;
        } else {
            return prev;
        }
    }, 0);
}
</script>

<template>
    <div class="border bg-white dark:bg-stone-800 dark:border-neutral-600 rounded-xl p-3 space-y-2">
        <p class="font-semibold text-2xl mt-1 dark:text-white">สิทธิ์การเข้าถึง</p>
        <select
            v-model="activeRole"
            class="md:hidden bg-neutral-50 border border-neutral-300 text-neutral-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-neutral-800 dark:border-neutral-600 dark:placeholder-neutral-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
        >
            <option value="all">ทั้งหมด</option>
            <option v-for="role in roleList" :key="'role-' + role.id" :value="role.id">{{ role.name }}</option>
        </select>
        <RoleListButton class="hidden md:flex" role="ทั้งหมด" :users-amount="users.length" :is-active="isActiveRole('all')" @click="setActiveRole('all')" />
        <RoleListButton
            v-for="role in roleList"
            :key="'role-' + role.id"
            class="hidden md:flex"
            :role="role.name"
            :users-amount="countUserEachRole(role.id)"
            :is-active="isActiveRole(role.id)"
            @click="setActiveRole(role.id)"
        />
    </div>
</template>
