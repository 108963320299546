<script lang="ts" setup>
import type { UserResponse } from '@/types/User';

// PROPS
interface Props {
    user: UserResponse;
}
const props = defineProps<Props>();
const { user } = toRefs(props);

// EMIT
type Emit = {
    edit: [];
};
defineEmits<Emit>();

// COMPUTED
const userLabel = computed<string>(() => `${user.value.firstName.substring(0, 1)}${user.value.lastName.substring(0, 1)}`.toUpperCase());
</script>
<template>
    <div class="grid grid-cols-5 rounded-xl cursor-pointer border dark:border-stone-800 dark:bg-stone-700 p-2 hover:bg-stone-200 dark:hover:bg-stone-700/80 w-full space-x-3 transition">
        <div class="col-span-4 flex w-full space-x-3">
            <Avatar class="aspect-square w-12 h-12">
                <AvatarImage :src="$api(user.imageUrl)" loading="lazy" :alt="userLabel"></AvatarImage>
                <AvatarFallback>{{ userLabel }}</AvatarFallback>
            </Avatar>
            <div>
                <p class="font-semibold dark:text-white break-words">{{ user.fullName }}</p>
                <p class="dark:text-neutral-400 break-words">{{ user.email }}</p>
            </div>
        </div>
        <div class="flex justify-end items-center">
            <DropdownMenu>
                <DropdownMenuTrigger as-child>
                    <Button variant="ghost" class="border border-stone-400 px-2 text-stone-400">
                        <IconCSS name="material-symbols:more-vert" size="1.5rem" />
                    </Button>
                </DropdownMenuTrigger>

                <DropdownMenuContent class="w-48">
                    <DropdownMenuGroup>
                        <DropdownMenuItem @click="$emit('edit')"><IconCSS name="material-symbols:edit-outline-rounded" class="mr-1" size="1.5rem" /> แก้ไขข้อมูลผู้ใช้งาน</DropdownMenuItem>
                    </DropdownMenuGroup>
                </DropdownMenuContent>
            </DropdownMenu>
        </div>
    </div>
</template>
