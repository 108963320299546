import cloneDeep from 'lodash/cloneDeep';
import type { UserResponse } from '~/types/User';
import userRoleTemplate from '@/utils/userRoleTemplate';

const userItem: UserResponse = {
    id: 1,
    firstName: '',
    lastName: '',
    fullName: '',
    username: '',
    email: '',
    confirmed: false,
    imageUrl: '/uploads/default/user.png',
    settings: {},
    role: cloneDeep(userRoleTemplate),
    isManager: false,
    actionRole: [],
    position: 'General',
    isActive: true,
    isCompletedSetup: true,
    createdById: null,
    updatedById: null,
    createdAt: '',
    updatedAt: '',
    createdBy: null,
    createdUsers: [],
    updatedBy: null,
    updatedUsers: [],
};

export default userItem;
